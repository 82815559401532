<route>
{
  "meta": {
    "permission": [
      "base_config.view_level"
    ]
  }
}
</route>

<template>
  <div>
    <diallevel v-model="dialog" :to-edit="toEdit" @done="reload = true" />
    <i-table
      :title="$tc('level', 2)"
      :headers="headers"
      app="base_config.level"
      api="customer.level"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
      :opt="{ company_id: this.company.pk }"
      :limit="4"
    >
      <template v-slot:item.description="{ item }">
        <span v-html="item.description"></span>
      </template>
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import diallevel from '../../components/level/modal.vue'
export default {
  components: {
    diallevel
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('description'),
          value: 'description'
        },
        {
          text:
            this.levelBtn.delete || this.levelBtn.edit ? this.$t('action') : '',
          value: this.levelBtn.delete || this.levelBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      company: 'company/getCompanyData',
      isAdmin: 'session/isAdmin'
    }),
    levelBtn() {
      return {
        delete:
          this.getPermissions([`base_config.delete_level`]) || this.isAdmin,
        edit: this.getPermissions([`base_config.change_level`]) || this.isAdmin
      }
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
